import Vue from 'vue'
import App from '@/modules/app.vue'
import { defaultGuards, initRouter, RouterInstance, routes } from './infrastructure/router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { loadVueLibs } from './infrastructure/setup/vue-libs'

const pinia = createPinia()

Vue.use(PiniaVuePlugin)

RouterInstance.router = initRouter({
  guards: defaultGuards,
  routes
})

loadVueLibs(Vue)

new Vue({
  router: RouterInstance.router,
  render: (h) => h(App),
  pinia
}).$mount('#app')
