export const updateMetaWithContent = (key: string, content?: string, attribute = 'name') => {
  let metaDescription = document?.querySelector(`meta[${attribute}="${key}"]`)
  if (content) {
    if (!metaDescription) {
      metaDescription = document.createElement('meta')
      document.getElementsByTagName('head')[0].appendChild(metaDescription)
      metaDescription.setAttribute(attribute, key)
    }
    metaDescription.setAttribute('content', content || '')
  } else if (metaDescription) {
    metaDescription.remove()
  }
}

export const resetAllTags = () => {
  const metaPropertiesToExclude = ['og:type', 'og:site_name', 'og:locale', 'twitter:site', 'version']
  Array.from(document?.querySelectorAll('meta[property^="og:"]'))
    .filter(
      (ogMeta) =>
        !metaPropertiesToExclude.includes(
          Array.from(ogMeta.attributes).find((attribute) => attribute.name === 'property')?.value || ''
        )
    )
    .forEach((ogMeta) => ogMeta.remove())

  Array.from(document?.querySelectorAll('meta[name^="twitter:"]'))
    .filter(
      (twitterMetta) =>
        !metaPropertiesToExclude.includes(
          Array.from(twitterMetta.attributes).find((attribute) => attribute.name === 'name')?.value || ''
        )
    )
    .forEach((twitterMetta) => twitterMetta.remove())
}
