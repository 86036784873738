import { defineStore } from 'pinia'
import { DownloadClientInstance } from '../download-client/dl-client'
import { Environment } from '@/infrastructure/adapters/environment-variables'

let versionInfoPromise: Promise<string> | null = null

export const useAppStore = defineStore('app', {
  state: (): { version: string | null } => ({
    version: null
  }),
  getters: {
    getVersion(state) {
      return state.version
    }
  },
  actions: {
    async loadVersionInfo(): Promise<string> {
      if (this.version) {
        return this.version
      }

      if (!versionInfoPromise) {
        versionInfoPromise = new Promise((resolve, reject) => {
          DownloadClientInstance.version_BuildVersion()
            .then((apiInfo) => {
              const apiMessage = (apiInfo.environment || '') + ' ' + (apiInfo.buildTag || '')
              this.version =
                Environment + (BUILD_VERSION ? ` ${BUILD_VERSION}` : '') + (apiMessage ? ` | API ${apiMessage}` : '')
              resolve(this.version)
            })
            .catch((error) => {
              versionInfoPromise = null // Reset the promise on error
              reject(error)
            })
        })
      }

      return versionInfoPromise
    }
  }
})
