import { updateMetaWithContent } from '@/infrastructure/helpers/meta-tags'
import { NavigationGuard } from 'vue-router'
import { IRoute } from '../interfaces'
import { useAppStore } from '@/infrastructure/store/app'

export const MetaDataHandler: NavigationGuard = async (to: IRoute, from: IRoute, next) => {
  await updateVersionMetaData()
  next()
}

export async function updateVersionMetaData() {
  const appStore = useAppStore()
  const version = await appStore.loadVersionInfo()
  updateMetaWithContent('version', version, 'name')
}
