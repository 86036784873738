import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { RequestForSelectieVersieHydraItem } from '@/infrastructure/download-client/download-client'
import { defineStore } from 'pinia'

export const usePredefinedDocumentsState = defineStore('predefined-documents', {
  state: (): { selectionEntityVersions: RequestForSelectieVersieHydraItem[] | null } => ({
    selectionEntityVersions: null
  }),
  actions: {
    async getAllSelectionEntityVersions() {
      return (
        this.selectionEntityVersions ||
        (await DownloadClientInstance.selectionEntities_GetAllSelectionEntityVersions().then((versions) => {
          const result = versions?.member || []
          this.selectionEntityVersions = result
          return result
        }))
      )
    }
  }
})
